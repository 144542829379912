<script>
    import {VStepper} from 'vue-stepper-component';
    import GoodWizard from '../../utility/vueGoodWizard';
    import Axios from 'axios';
    import _ from "lodash";
    import ImageSelector from '../../utility/image-selector';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import {appConfig} from '../../../config.js';
    import {mapActions, mapGetters} from 'vuex';
    import Multiselect  from "vue-multiselect";

    export default {
        name: 'estimate-options-import-pdf',
        components: {
            VStepper,
            GoodWizard,
            ImageSelector,
            vueDropzone: vue2Dropzone,
            Multiselect,
        },
        data: function () {
            return {
                reactClickOnParts: 0,
                parts: [],
                isCanSend: true,
                allPartsSelected: false,
                steps: [
                    {
                        label: 'Upload Estimate PDF',
                        slot: 'estimate-options-import-pdf-step-1',
                    },
                    {
                        label: 'Confirm imported',
                        slot: 'estimate-options-import-pdf-step-2',
                        options: {
                            nextDisabled: true,
                        },
                    }
                ],
                step: 1,
                fileUploadDropzoneOptions: {
                    headers: {"Authorization": localStorage.getItem('token')},
                    url: appConfig.baseAPIURL + '/fe/estimate/import-pdf',
                    method: 'post',
                    maxFilesize: 20,
                    // maxFiles: 2,     //Can be used to limit the maximum number of files that will be handled by this Dropzone
                    thumbnailWidth: 200,
                    addRemoveLinks: true,
                    acceptedFiles: 'application/pdf',
                    dictFileTooBig: "Your file has exceeded the maximum limit of 20MB per file. Please reduce the file size and upload again",
                },
            };
        },
        methods: {
            goToEstimate() {
              this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab, subTab: 'Part', itemsListId: this.$route.params.estimate_id}})
            },
            successFileUpload(file, response) {
                this.$refs.fileUpDrop.removeAllFiles();
                let vm=this
                if (response._status) {
                    this.parts = response.data;
                    this.parts = this.parts.map(part => {
                        vm.$set(part, 'selected', false);
                        return part;
                    });
                    this.$refs.estimateOptionsParseIbodyshopPdf.goNext();
                } else {
                    toastr.error(response.message);
                }

            },
            clickCancelDropzoneFiles: function () {
                this.$refs.fileUpDrop.removeAllFiles(true);
                this.clearFileIdsInCardEdit();
            },
            addFileUpload($event) {
                $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = "none";
            },
            removeFileDropzone($event) {

            },
            completeFileUpload($event) {
                $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute("style");
            },
            sendFileUpload($event) {
                $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = "none";
            },
            sendFileRemove: function (file) {

            },
            toggleSelectAllParts() {
                if (this.allPartsSelected) {
                    this.parts.forEach(part => part.selected = false);
                   // this.allPartsSelected = false;
                } else {

                    this.parts.forEach(part => part.selected = true);
                   // this.allPartsSelected = true;
                }
            },
            clickCancel: function () {
                this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab, subTab: 'Part', itemsListId: this.$route.params.estimate_id}})
                return;
            },
            clickImport: function () {
                if (!this.isCanSend) {
                  return;
                }

                this.isCanSend = false;

                let estimate_id = this.$route.params.estimate_id;
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/estimate/` + this.$route.params.estimate_id + `/import-pdf`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify({
                        parts: this.selectedParts,
                    })
                })
                    .then(response => {
                        if (response.status == 200) {
                            if (response.data._status === false){
                                toastr.error(response.data.msg);
                            }
                            this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab, subTab: 'Part', itemsListId: this.$route.params.estimate_id}})
                        } else {
                            toastr.error(response.data.msg);
                        }
                    })
                    .catch(error => {

                        toastr.error(error);
                    })
                    .finally(r => {
                        NProgress.done();
                        this.isCanSend = true;
                    });

            },
            onImageSelect: function (imgs) {
                // console.log(imgs)
                this.selectedImages = imgs;
            }
        },
        computed: {
            ...mapGetters({
                getterBusinessName : 'getBusinessName',
            }),
            computedDataForHeader() {
              return this.$route.params.dataForHeader;
            },
            computedEstimateId() {
              return  this.$route.params.parentId;
            },
            isAllpartSelected() {
                this.reactClickOnParts;
                if (this.selectedParts && this.selectedParts.length > 0 && this.selectedParts.length === this.parts.length)
                {
                    this.allPartsSelected = true;
                    console.log('allPartsSelected', this.allPartsSelected)
                    return true;
                }else{
                    this.allPartsSelected = false;
                    console.log('allPartsSelected', this.allPartsSelected)
                    return false;
                }
            },
            selectedParts() {
                this.reactClickOnParts++;
                console.log('reactClickOnParts', this.reactClickOnParts)
                //if (this.allPartsSelected) {
                //}
                let prs = this.parts;
                try {
                    let arr = _.filter(prs, {'selected': true});
                    console.log('prs.arr', arr)
                    let res = [];
                    _.forEach(arr, function (i) {
                        res.push(i);
                    });
                    return res;
                } catch (e) {
                    return [];
                }
            },
            selectedPartsCount(){
              return this.selectedParts.length;
            },
        },
        mounted: function () {

        },
    };

</script>

<template>
    <div class="estimate-options-import-pdf">
        <div class="page-header">
            <h4>Import Pricing - {{computedDataForHeader.estimateNumber}}{{computedDataForHeader.ownerName}}{{computedDataForHeader.rego}}</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home"> <router-link :to="'/'">Home</router-link></li>
                <li class="breadcrumb-item"> <router-link :to="'/estimates/'">View Estimates</router-link></li>
                <li class="breadcrumb-item"><a href="" @click.prevent="goToEstimate">Edit Estimate</a></li>
                <li class="breadcrumb-item active">Import Pricing</li>
            </ol>
            <h4 class="header-display-name">{{ getterBusinessName }}</h4>
        </div>
        <div class="">

            <good-wizard
                    :steps="steps"
                    :currentStep="step"
                    ref="estimateOptionsParseIbodyshopPdf"
                    >
                <div slot="estimate-options-import-pdf-step-1" class="estimate-options-import-pdf-step-1">
                    <div class="header">
                        Upload Estimate PDF
                    </div>
                    <div class="subheader">
                      Upload a PDF copy of the Estimate for import processing
                    </div>
                    <div class="card-files" style="padding-right: 30px;">
                        <vue-dropzone
                            v-on:vdropzone-complete="completeFileUpload"
                            v-on:vdropzone-sending="sendFileUpload"
                            v-on:vdropzone-success="successFileUpload"
                            ref="fileUpDrop"
                            id="fileUploadDrop"
                            class="dropify-wrapper dropzoneContainer"
                            :options="fileUploadDropzoneOptions"
                            :useCustomSlot="true">
                            <div class="dropify-message" style="transform: translateY(0%); min-width: 125px;">
                                <i class='bx bx-cloud-upload' ></i>
                                <p class="dnd-title">Drag and Drop File Here</p>
                                <p class="text-or mb-0-5 dnd-or">Or</p>
                                <div class="text-sm-center mb-1 ex-image-upload-buttons">
                                    <button  type="button" class="btn btn-primary waves-effect waves-light browse-btn browse-btn-files">Browse Files</button>
                                </div>
                            </div>
                        </vue-dropzone>
                    </div>
                </div>
                <div slot="estimate-options-import-pdf-step-2" class="estimate-options-import-pdf-step-2">
                    <div class="header">
                      Confirm Imported
                    </div>
                    <div class="subheader">
                      Confirm the Parts information to import by ticking the box next to each one or select all.
                    </div>
                    <div class="box box-block rs-scroll rs-scroll--y" style="overflow: auto;">
                        <div class="row">
                            <table class="table b-table select-parts">
                                <thead class="bg-navyblue">
                                <tr>
                                    <th class="header-check">
                                        <label class="form-check-inline checkbox">
                                            <input @click="toggleSelectAllParts" class="form-check-input" type="checkbox" v-model="allPartsSelected">
                                            <span class="icon"><i class='bx bx-check'></i></span>
                                        </label>
                                    </th>
                                    <th class="header-part-name" style="text-align: left;">Part Name</th>
                                    <th class="header-part-number" style="text-align: left;">Part Number</th>
                                    <th class="header-part-type" style="text-align: left;">Part Type</th>
                                    <th class="header-part-qty">Qty</th>
                                    <th class="header-part-cost">Buy</th>
                                    <th class="header-part-list">List</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(partItem, index) in parts" :key="index">
                                    <td>
                                        <label class="form-check-inline checkbox">
                                            <input class="form-check-input" type="checkbox" v-model="partItem.selected" @click="reactClickOnParts +=1">
                                            <span class="icon"><i class='bx bx-check'></i></span>
                                        </label>
                                    </td>
                                    <td class="part-name">{{ partItem.name }}</td>
                                    <td class="part-number">{{ partItem.number }}</td>
                                    <td class="part-type">
                                      <multiselect
                                          ref="partType"
                                          :options="['Select', 'New', 'Parallel', 'Alternate', 'Aftermarket', 'Used', 'Recondition', 'Exchange']"
                                          v-model="partItem.type"
                                          :showLabels="false"
                                          :option-height="29"
                                          :max-height="203"
                                          style="width: 120px"
                                          :open-direction="index > 2 ? 'top': 'bottom'"
                                          :close-on-select="true"
                                          placeholder="Select type">
                                      </multiselect>
                                    </td>
                                    <td class="part-qty text-center">{{ partItem.quantity }}</td>
                                    <td class="part-cost text-center"></td>
                                    <td class="part-list text-center">{{ partItem.price | formatMoney }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </good-wizard>

            <div class="wizard__buttons">
                <div class=" pull-right">
                <button
                        class="btn btn-outline-primary pull-left button-cancel mr-0"
                        type="button"
                        @click="clickCancel"
                >
                    Cancel
                </button>
                <button
                        v-if="$refs.estimateOptionsParseIbodyshopPdf && $refs.estimateOptionsParseIbodyshopPdf.currentStep != 0"
                        class="btn btn-outline-primary-light pull-left button-back mr-0 ml-1"
                        type="button"
                        @click="$refs.estimateOptionsParseIbodyshopPdf.goBack()"
                >
                    Back
                </button>
                <button
                        v-if="$refs.estimateOptionsParseIbodyshopPdf && $refs.estimateOptionsParseIbodyshopPdf.currentStep != 1"
                        class="btn btn-primary pull-right button-next"
                        type="button"
                        @click="$refs.estimateOptionsParseIbodyshopPdf.goNext()"
                        :tabindex="4"
                >
                    Next
                </button>
                <button
                        v-if="$refs.estimateOptionsParseIbodyshopPdf && $refs.estimateOptionsParseIbodyshopPdf.currentStep == 1"
                        class="btn btn-primary pull-right button-card-estimate"
                        type="button"
                        @click="clickImport"
                        style=""
                        :disabled="!selectedPartsCount || !isCanSend"
                >
                    Import
                </button>

                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .V3 .estimate-options-import-pdf .wizard__body {
        padding: 15px;
        width: 100%;
    }

    .V3 .estimate-options-import-pdf .wizard__body .estimate-options-import-pdf-step-1 {
        width: 100%;
    }

    .V3 .estimate-options-import-pdf .wizard__body .estimate-options-import-pdf-step-2 {
        width: 100%;
    }

    .V3 .estimate-options-import-pdf .wizard__body .estimate-options-import-pdf-step-2 .table th {
        color: #1C1F39;
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
     /*   vertical-align: top; */
    }

    .V3 .estimate-options-import-pdf .wizard__body .estimate-options-import-pdf-step-2 .table td {
        color: #1C1F39;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
    }

    .V3 .estimate-options-import-pdf .wizard__body .estimate-options-import-pdf-step-2 {
        width: 100%;
    }

    .V3 .estimate-options-import-pdf .wizard__buttons {
        width: 75%;
        margin: 0 0 70px 5px;
        padding-right:15px;
    }

</style>
<style scoped>
    .V3 .estimate-options-import-pdf >>> .card-block {
        padding: 0px;
    }

    .V3 .estimate-options-import-pdf .card-block {
        padding: 0px;
    }


    .V3 .wizard__buttons .pull-left {
        float: left !important;
        margin-right: 10px;
    }

    .V3 .wizard__buttons .pull-right {
        float: right !important;
        margin-left: 10px;
    }

    .V3 .wizard__buttons .btn {
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        height: 38px;
    }

    .V3 .wizard__buttons .button-cancel {
        padding: 10px 21px;
        background: #FFFFFF;
        border: 1px solid rgba(28, 31, 57, 0.25);
        color: #1C1F39;
    }

    .V3 .wizard__buttons .button-card {
        padding: 10px 15px;
        background: #FFFFFF;
        color: #5E79FF;
    }

    .V3 .wizard__buttons .button-card-estimate {
        padding: 10px 15px;
    }

    .V3 .wizard__buttons .button-next {
        padding: 10px 15px;
        width: 83px;
    }

    .V3 .wizard__buttons .button-back {
        padding: 10px 26px;
        background: #FFFFFF;
        border-color: #5E79FF;
        width: 83px;
    }

    @media (max-width: 823px) AND (min-width: 604px) {
        .V3 .card-new .wizard__buttons {
            padding-left: 15px;
            padding-right: 15px;
            width: auto;
            min-width: auto;
        }

        .V3 .compact-sidebar .card-new .wizard__buttons {
            width: 573px;
            margin: 0 auto;
            min-width: 573px;
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    @media (max-width: 603px) {
        .V3 .card-new .wizard__buttons {
            padding-left: 15px;
            padding-right: 15px;
            width: auto;
            min-width: auto;
        }
    }

    @media (max-width: 512px) {
        .V3 .card-new .wizard__buttons button {
            margin-bottom: 10px;
            float: none !important;
            margin-left: 0px;
            margin-right: 0px;
            display: block;
        }
    }

</style>

<style scoped>
    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 th {
        vertical-align: top;
        padding-top: 35px;
        text-align: center;
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
    }

    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 td {
        padding: 13px 15px;
    }

    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 .header-part-type,
    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 .part-type {
        width: 100px;
    }

    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 .header-part-qty,
    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 .part-qty {
        width: 100px;
    }

    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 .header-part-cost,
    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 .part-cost {
        width: 100px;
    }

    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 .header-part-list,
    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 .part-list {
        width: 100px;
    }

    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 th.header-check {
        padding: 0.75rem;
        width: 30px;
        min-width: 30px;
    }

    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 .header-check .form-check-inline {
        background-color: #ffffff;
        border-radius: 3px;
    }


    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 .select-parts .select-parts-check-all span.icon,
    .V3 .estimate-options-import-pdf .estimate-options-import-pdf-step-2 .select-parts span.icon {
        margin-right:0px;
    }
</style>
