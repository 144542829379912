<script>
import Axios from 'axios'
import { mapGetters } from 'vuex'
import Summernote from '../utility/summernote'
import DatePicker from '../utility/date-picker'
import NumberFormatter from '../utility/number-formatter'
import Multiselect from 'vue-multiselect'
import CardNotes from '../utility/card-notes'
import { appConfig } from '../../config.js'
import _ from 'lodash'
import InvoiceNotes from '@/components/utility/invoice-notes'
import SmallFormWizard from '../small-form-wizard.vue'

export default {
  name: 'invoice-excess',
  data: function () {
    return {
      isAutoSave: false,
      autoSaveMessage: '',
      classTabContent: 'tab-content',
      isHiddenOptions: false,
      isHiddenNewNote: true,
      isHiddenSave: false,
      notes: [],
      heightOptions: '',
      flagPrintReceipt: 0,
      currentTab: 'details',
      readOnlyCustomerName: true,
      readOnlyCustomerAddress: true,
      is_change_customer_name: false,
      is_change_customer_address: false,
      is_set_other_insurer_name: false,
      is_set_other_insurer_address: false,
      is_set_other_insurer_abn: false,
      summary: {
        Excess: '',
        Excess_str: '',
      },
      is_sent_el: false,
      switch: { size: 'small', color: '#3E70C9' },
      paid: {
        amount: 0,
        due: 0,
        date: '',
        payment_type: '',
      },
      previewInvoice: {},
      invoice: {
        id: '',
        number: '',
        card_id: '',
        created_date: '',
        due_date: '',
        due: '',
        due_str: '',
        is_sent: false,
        list_detail: false,
        manual_customer_name: null,
        manual_customer_address: null,
        customer: {
          name: '',
          address: '',
        },
        transactions: {},
        status: null,
        isAutoSave: false,
      },
      card: {
        customer: {
          name: '',
          mobile: '',
          email: '',
          customer_type: '',
          phone: '',
        },
        vehicle: {
          customer_vehicle_id: null,
          dom: '',
          rego_number: '',
          make: '',
          model: '',
          series: '',
          colour: '',
          vin: '',
          odometer: 0,
          transmission: '',
          build_date: '',
          cylinders: '',
        },
        booking_date: '',
        completion_date: '',
        card_number: '',
        card_status: '',
        card_id: '',
        all_estimate_numbers: '',
        estimate_id: 0,
      },
      terms: '',
      cardProgress: {
        startIndex: 0,
        color: '#142742',
        stepSize: 'xs',
        width: '',
        widthActiveProgress: '',
      },
      progressStatus: [],
    }
  },
  computed: {
    ...mapGetters({
      'vendorInfo': 'vendorInfo',
      'isCustomerUser': 'isCustomerUser',
      'isStatusActive': 'isStatusActive',
      getterBusinessName: 'getBusinessName',
      'ceStatuses': 'ceStatuses',
    }),
    filteredStatusList() {
      let currentIndex = this.card?.statuses?.findIndex(
          (status) => Number(status.id) === Number(this.card.card_status)
      );
      if (this.card?.statuses?.length <= 4) return this.card?.statuses;

      const statusCount = this.card?.statuses?.length;
      const index = currentIndex;

      if (index < 3) {
        // on minimal
        return this.card?.statuses?.slice(0, 4);
      } else if ((statusCount - index + 1) < 5) {
        // on maximum
        return this.card?.statuses?.slice(-4);

        // everything in between
      } else if (index === 3 || index === 4) {
        return this.card?.statuses?.slice(2, 6);
      } else if (index === 5 || index === 6) {
        return this.card?.statuses?.slice(4, 8);
      } else if (index === 7 || index === 8) {
        return this.card?.statuses?.slice(6, 10);
      } else if (index === 9 || index === 10) {
        return this.card?.statuses?.slice(8, 12);
      }
    },
    userInfo: function () {
      return this.$store.state.userInfo
    },
    isAutoSaveEnable () {
      return !!(this.invoice.isAutoSave)
    },
    isStatusPaid () {
      return (this.invoice.status === 'paid')
    },
    isStatusAwaitingPayment () {
      return (this.invoice.status === 'awaiting')
    },
    totalNotes: function () {
      return this.notes.length
    },
    totalTransactions () {
      return this.invoice.transactions.length
    },
    isReadOnlyCustomerAddress: function () {
      if (this.readOnlyCustomerAddress) {
        return true
      }
      return false
    },
    isReadOnlyCustomerName: function () {
      if (this.readOnlyCustomerName) {
        return true
      }
      return false
    },
    cardProgressBarStatuses () {
      let statuses = []
      let currentStatusIndex = 0
      this.ceStatuses.forEach((ce_status, i) => {
        if (Number(ce_status.ce_status_id) === Number(this.card.card_status)) {
          currentStatusIndex = i
        }
      })
      // let count = this.ceStatuses.length
      // if (count > 0) {
      //   this.cardProgress.width = 'width: ' + (count * 89.3) + 'px'
      //   this.cardProgress.widthActivePercent = 'width: ' + (89.3 * (currentStatusIndex + 1) - 25) + 'px'
      // } else {
      //   this.cardProgress.width = 'width: 0px'
      //   this.cardProgress.widthActivePercent = 'width: 0px'
      // }

      this.cardProgress.startIndex = currentStatusIndex
      this.ceStatuses.forEach((ce_status, i) => {
        let status = {
          id: ce_status.ce_status_id,
          title: ce_status.name,
          active: false,
        }
        if (currentStatusIndex >= i) {
          status.active = true
          status.icon = 'ti-check'
        } else {
          status.active = false
          status.icon = ' '
        }
        statuses.push(status)
      })

      return statuses
    },
  },
  watch: {
    ceStatuses: {
      handler() {
        this.$nextTick(() => {
          this.updateCardProgressBar()
        })
      },
      immediate: true
    },
  },
  methods: {
    onClickNumber(v) {
      window.open('tel:' + v);
    },
    updateCardProgressBar () {
      //this.showUnsavedChanges();
      if (this.card.statuses) {
        this.card.statuses.splice(0)
      } else {
        this.$set(this.card, 'statuses', [])
      }
      let currentStatusIndex = 0
      this.ceStatuses.forEach((ce_status, i) => {
        if (ce_status.ce_status_id.toString() === this.card.card_status) {
          currentStatusIndex = i
        }
      })

      this.cardProgress.startIndex = currentStatusIndex
      this.ceStatuses.forEach((ce_status, i) => {
        let status = {
          id: ce_status.ce_status_id,
          title: ce_status.name,
          active: false,
        }
        if (currentStatusIndex >= i) {
          status.active = true
          status.icon = 'ti-check'
        } else {
          status.active = false
          status.icon = ' '
        }
        this.card.statuses.push(status)
      })
      this.updateCardProgressBarSmall()
    },
    updateCardProgressBarSmall() {
      let currentStatusIndexSmall = 0
      this.filteredStatusList.forEach((ce_status, i) => {
        if (ce_status.id.toString() === this.card.card_status) {
          currentStatusIndexSmall = i
        }
      })
      let countSmall = this.filteredStatusList.length

      let el = document.querySelector("#invoice-details > div:nth-child(2) > div.col-md-12.col-lg-4 > div > div.card.ex-card.wizard-bottom > div > div.wizard-navigation > ul")

      let width = 134
      if (el && el.clientWidth) {
        width = el.clientWidth / 4
        if (el.clientWidth > 365 && el.clientWidth <= 460) {
          width += 5
        }
        if (el.clientWidth > 460) {
          width += 15
        }
      }
      if (countSmall > 0) {
        this.cardProgress.width = 'width: ' + (countSmall * 89.3) + 'px'
        if (currentStatusIndexSmall == 0) {
          this.$set(this.cardProgress, 'widthActivePercent', 'width: 25px')
        } else {
          this.$set(this.cardProgress, 'widthActivePercent', 'width: ' + (width * (currentStatusIndexSmall)) + 'px')
        }
      } else {
        this.cardProgress.width = 'width: 0px'
        this.$set(this.cardProgress, 'widthActivePercent', 'width: 0px')
      }
    },
    onChangeCardProgress: function (id) {
      if (!this.isStatusActive) {
        return
      }
      this.updateCardProgressBarSmall()
      this.card.card_status = id
      let data = {
        card: {
          card_status: id,
        }
      }
      let invoice_id = this.$route.params.invoice_id
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/invoice/${invoice_id}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: JSON.stringify({ data })
      })
          .then(response => {
            if (response.status == 200) {
              toastr.success('Progress has been updated')
              this.updateCardProgressBarSmall()
            } else {
              toastr.error(response.data.msg)
            }

            NProgress.done()
          })
          .catch(error => {
            NProgress.done()
            toastr.error(error)
          })
    },
    markAsUnpaid () {
      let data = { 'unpaid': true }
      this.saveInvoice(data)
      this.autoUpdate()
    },
    sendToXero () {
      Axios.post(`/fe/invoice/${this.invoice.id}/send-to-xero`)
          .then(response => {
            if (response.data && response.data._status) {
              toastr.success('Successfully sent to Xero')
              this.loadInvoice(this.invoice.id)
            } else {
              toastr.error(response.data._error)
            }
          })
          .catch(e => console.error(e))
    },
    viewOnXero () {
      Axios.post(`/fe/invoice/${this.invoice.id}/view-on-xero`)
          .then(response => {
            if (response.data && response.data._status) {
              window.open(response.data.url, '__blank')
            }
          })
          .catch(e => console.error(e))
    },
    loadInvoice (invoice_id) {
      NProgress.start()
      Axios.get(`/fe/invoice/${invoice_id}`)
          .then(response => {
            this.card = response.data.card
            this.summary = response.data.summary
            this.invoice = response.data.excess_invoice
            this.terms = response.data.terms
            this.loadNotes()
            this.updateCardProgressBar();
            this.$nextTick(() => {
              this.isAutoSave = true
            })

          })
          .catch(error => {
            console.log(error)
            toastr.error('Excess Invoice not found')
            //this.$router.push('/invoices');
          })
          .finally(() => {
            this.$nextTick(() => {
              NProgress.done()
            })
          })
    },
    beforeDebouncedAutoSave: function () {
      if (!this.isAutoSaveEnable) {
        return
      }
      this.debouncedAutoSave()
    },
    autoUpdate () {
      if (!this.isAutoSaveEnable || !this.isAutoSave) {
        return
      }
      this.beforeDebouncedUnsavedChanges()
      this.beforeDebouncedAutoSave()
    },
    beforeDebouncedUnsavedChanges: function () {
      if (!this.isAutoSaveEnable || !this.isAutoSave) {
        return
      }
      this.debouncedUnsavedChanges()
    },
    showUnsavedChanges: function () {
      this.autoSaveMessage = 'Unsaved Changes'
    },
    autoSave: function () {
      this.saveAsActive()
    },
    addNote: function () {
      this.$refs['cardNotes'].addNote()
    },
    detailsTabChange: function () {
      this.currentTab = 'details'
      this.classTabContent = 'tab-content'
      this.isHiddenNewNote = true
      this.isHiddenSave = false
      this.$storage.set('views.invoiceExcess.' + this.$route.params.invoice_id + '.defaultView', 'details')
    },
    transactionTabChange: function () {
      this.currentTab = 'transaction'
      this.classTabContent = 'tab-content'
      this.isHiddenNewNote = true
      this.isHiddenSave = true
      this.$storage.set('views.invoiceExcess.' + this.$route.params.invoice_id + '.defaultView', 'transaction')
    },
    notesTabChange: function () {
      this.currentTab = 'notes'
      this.classTabContent = 'tab-content-table'
      this.isHiddenNewNote = false
      this.isHiddenSave = true
      this.$storage.set('views.invoiceExcess.' + this.$route.params.invoice_id + '.defaultView', 'notes')
    },
    loadNotes: function () {
      let invoice_id = this.invoice.id
      Axios.get(`/fe/invoice/${invoice_id}/notes`)
          .then(response => {
            if (response.data._status) {
              this.notes = response.data.notes
            }
          })
          .catch(error => {
            NProgress.done()
          })
    },
    completeVoidInvoice: function () {
      this.$refs.voidInvoiceModal.hide()
      let invoice_id = this.$route.params.invoice_id
      var self = this
      NProgress.start()
      Axios.post(`/fe/invoice/${invoice_id}/void`)
          .then(response => {
            toastr.success('Excess Invoice has been deleted')
            NProgress.done()
            this.$router.push('/invoices')
          })
          .catch(error => {
            toastr.error('Error')
            NProgress.done()
          })
    },
    markAsVoidInvoice: function () {
      this.$refs.voidInvoiceModal.show()
    },
    setOtherInsurerName: function () {
      this.is_set_other_insurer_name = true
      this.autoUpdate()
    },
    setOtherInsurerAddress: function () {
      this.is_set_other_insurer_address = true
      this.autoUpdate()
    },
    setOtherInsurerAbn: function () {
      this.is_set_other_insurer_abn = true
      this.autoUpdate()
    },
    changeCustomerAddress: function () {
      this.is_change_customer_address = true
      this.autoUpdate()
    },
    editCustomerAddress: function () {
      this.readOnlyCustomerAddress = false
    },
    blurCustomerAddress: function () {
      this.readOnlyCustomerAddress = true
    },
    changeCustomerName: function () {
      this.is_change_customer_name = true
      this.autoUpdate()
    },
    editCustomerName: function () {
      this.readOnlyCustomerName = false
    },
    blurCustomerName: function () {
      this.readOnlyCustomerName = true
    },
    showPaidModal: function () {
      this.$router.push({
        name: 'invoiceMarkAsPaidForExcess',
        params: {
          invoice_id: this.$route.params.invoice_id,
          currentTab: this.currentTab,
        }
      })
    },
    saveAsDraft: function () {
      let data = {
        status: 'draft',
        invoice: this.invoice,
        terms: this.terms,
        vehicle: this.card.vehicle,
        carddatein: this.card.booking_date,
        carddateout: this.card.completion_date,
        is_change_customer_address: this.is_change_customer_address,
        is_change_customer_name: this.is_change_customer_name,
        is_set_other_insurer_name: this.is_set_other_insurer_name,
        is_set_other_insurer_address: this.is_set_other_insurer_address,
        is_set_other_insurer_abn: this.is_set_other_insurer_abn,
      }
      this.saveInvoice(data)
    },
    saveAsActive: function () {
      let data = {
        status: 'active',
        invoice: this.invoice,
        terms: this.terms,
        vehicle: this.card.vehicle,
        carddatein: this.card.booking_date,
        carddateout: this.card.completion_date,
        is_change_customer_address: this.is_change_customer_address,
        is_change_customer_name: this.is_change_customer_name,
        is_set_other_insurer_name: this.is_set_other_insurer_name,
        is_set_other_insurer_address: this.is_set_other_insurer_address,
        is_set_other_insurer_abn: this.is_set_other_insurer_abn,
      }
      this.saveInvoice(data)
    },
    markAsAwaitingPayment: function () {
      let data = { status: 'awaiting' }
      this.saveInvoice(data)
    },
    showCustomerModal: function () {
      this.$router.push({
        name: 'invoiceSendToCustomer',
        params: { invoice_id: this.$route.params.invoice_id, isExcessInvoice: true, currentTab: this.currentTab, }
      })
    },
    downloadExcessInvoice: function () {
      let invoice_id = this.$route.params.invoice_id
      let token = localStorage.getItem('token')
      window.open(appConfig.baseAPIURL + `/fe/pdf/invoice/${invoice_id}/excess?at=${token}`)
      return
    },
    downloadExcessReceipt: function () {
      let invoice_id = this.$route.params.invoice_id
      let token = localStorage.getItem('token')
      window.open(appConfig.baseAPIURL + `/fe/pdf/invoice/${invoice_id}/excess/receipt?at=${token}`)
      return
    },
    sctollToTop: function () {
      setTimeout(() => {
        document.getElementsByClassName('modal-dialog')[0].scrollIntoView()
        return true
      }, 100)
    },

    showPreviewExcessInvoiceModal: function () {
      let invoice_id = this.$route.params.invoice_id
      var self = this
      NProgress.start()
      Axios.get(`/fe/invoice/${invoice_id}`)
          .then(response => {
            self.previewInvoice = response.data
            self.previewInvoice.invoice = response.data.excess_invoice
            NProgress.done()
            self.$refs.previewExcessInvoiceModal.show()
          })
          .catch(error => {
            toastr.error('Error occurred while loading invoice info')
            NProgress.done()
          })

    },
    saveInvoice: function (data) {
      let invoice_id = this.$route.params.invoice_id
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/invoice/${invoice_id}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: JSON.stringify({ data })
      })
          .then(response => {
            if (response.status == 200) {
              if (response.data.result.paid) {
                this.invoice.due = response.data.result.paid.due
                this.invoice.due_str = response.data.result.paid.due_str
              }
              if (response.data.result.status) {
                this.invoice.status = response.data.result.status
              }
              if (response.data.result.transactions) {
                this.invoice.transactions = response.data.result.transactions
              }
              if (!this.isAutoSaveEnable) {
                toastr.success('Invoice updated')
              }
              this.autoSaveMessage = 'Saved'

              if (this.flagPrintReceipt) {
                this.downloadExcessReceipt()
                this.flagPrintReceipt = 0
              }
            } else {
              if (!this.isAutoSaveEnable) {
                toastr.error(response.data.msg)
              }
            }
            NProgress.done()
          })
          .catch(error => {
            NProgress.done()
            if (!this.isAutoSaveEnable) {
              toastr.error(error)
            }
          })
    },
    updateHeightOptionsMenu (event) {
      if (document.body.offsetHeight < 450) {
        this.heightOptions = document.body.offsetHeight - event.clientY - 30
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    this.$storage.remove('views.invoiceExcess.' + this.$route.params.invoice_id + '.defaultView')
    next()
  },
  mounted: function () {
    if (this.$route.params.currentTab) {
      this.currentTab = this.$route.params.currentTab
    } else if (!_.isEmpty(this.$storage.get('views.invoiceExcess.' + this.$route.params.invoice_id + '.defaultView'))) {
      this.currentTab = this.$storage.get('views.invoiceExcess.' + this.$route.params.invoice_id + '.defaultView')
    }

    //this.is_sent_el = document.querySelector('#issent-switch');
    if (this.currentTab === 'notes') {
      this.isHiddenNewNote = false
      this.isHiddenSave = true
    }
    let invoice_id = this.$route.params.invoice_id
    this.loadInvoice(invoice_id)
    window.addEventListener('resize', this.updateCardProgressBarSmall)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateCardProgressBarSmall)
  },
  created () {
    this.debouncedAutoSave = _.debounce(this.autoSave, 500)
    this.debouncedUnsavedChanges = _.debounce(this.showUnsavedChanges, 100)
  },
  components: {
    Summernote,
    DatePicker,
    NumberFormatter,
    Multiselect,
    CardNotes,
    InvoiceNotes,
    SmallFormWizard,
  }
}
</script>

<template>
  <div class="ex-invoice-excess invoice-excess-page">
    <div class="page-header">
      <h4>Invoice - {{ invoice.number }} <span class="text-muted">|</span> {{ invoice.customer.name }} <span
          class="text-muted">|</span> {{ card.vehicle.make }}-{{ card.vehicle.model }}
        <span v-if="isAutoSaveEnable" class="autosave">{{ autoSaveMessage }}</span>
      </h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/invoices">Invoices</router-link>
        </li>
        <li class="breadcrumb-item active">View Excess Invoice</li>
      </ol>
      <h4 class="header-display-name">{{ getterBusinessName }}</h4>
      <div v-if="!isHiddenOptions" class="page-header-nav-options">
        <div class="navbar-nav nav dropdown dropdown-options  show inline">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                  @click="updateHeightOptionsMenu($event)">
            Options <span class="dropdown-arrow  pull-right" style="
                                                  box-sizing: border-box;
                                                  border-left: 1px solid rgba(27,30,56,0.25);
                                                  ">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp" aria-labelledby="dropdownMenuLink"
               :style="{'max-height': heightOptions + 'px'}" :class="{'options-menu-height': heightOptions != ''}">
            <a v-if="!isAutoSaveEnable && isStatusActive" @click="saveAsActive" class="dropdown-item"> Save</a>
            <a @click="showPreviewExcessInvoiceModal" class="dropdown-item"> Print Excess Invoice</a>
            <a @click="downloadExcessReceipt" class="dropdown-item"> Print Receipt</a>
            <a v-if="isStatusActive" @click="showCustomerModal" class="dropdown-item"> Send To Customer</a>
            <a v-if="vendorInfo.activeXeroAuth && !invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero"
               @click="sendToXero" class="dropdown-item">Send to Xero</a>
            <a v-if="vendorInfo.activeXeroAuth &&  invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero"
               @click="sendToXero" class="dropdown-item">Update on Xero</a>
            <a v-if="vendorInfo.activeXeroAuth &&  invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero"
               @click="viewOnXero" class="dropdown-item">View on Xero</a>
            <a v-if="isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" class="dropdown-item">View on XERO</a>
            <!--                        <a @click="markAsAwaitingPayment" class="dropdown-item"> Mark As Awaiting Payment</a>-->
            <a v-if="isStatusActive" @click="markAsVoidInvoice" class="dropdown-item"> Mark As Void</a>
            <a v-if="(isStatusAwaitingPayment || invoice.due!=0) && isStatusActive" @click="showPaidModal"
               class="dropdown-item"> Mark As Paid</a>
            <a v-if="(isStatusPaid || +invoice.amount !== invoice.due) && isStatusActive" @click="markAsUnpaid"
               class="dropdown-item"> Mark As Unpaid</a>
          </div>
        </div>
      </div>
    </div>
    <div class="box-block bg-white ex-main-box">
      <div class="ex-main-card">

        <div class="tabs-component">
          <ul class="nav nav-tabs nav-tabs-2 float-sm-left">
            <li class="tabs-component-tab nav-item">
              <a href="#invoice-details" @click="detailsTabChange()" data-toggle="tab"
                 :class="{'active': currentTab === 'details'}" class="tabs-component-tab-a nav-link"> Details</a>
            </li>
            <li class="nav-item">
              <a href="#activity" @click="transactionTabChange()" data-toggle="tab"
                 :class="{'active': currentTab === 'transaction'}" class="tabs-component-tab-a nav-link">
                Transaction History
                <span v-if="totalTransactions" class="tag tag-info">{{ totalTransactions }}</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="#card-notes" @click="notesTabChange()" data-toggle="tab"
                 :class="{'active': currentTab === 'notes'}" class="tabs-component-tab-a nav-link ">
                Notes
                <span v-if="totalNotes" class="tag tag-info">{{ totalNotes }}</span></a>
            </li>
          </ul>
          <div class="tab-header rs-btn-group float-sm-right mt-1-sm">
            <div v-if="!isAutoSaveEnable && !isHiddenSave && isStatusActive" class="tabs-nav-right-btn">
              <button @click="saveAsActive" type="button" class="btn btn-primary w-min-sm waves-effect waves-light"
                      style="margin-right:5px">Save
              </button>
            </div>
            <div v-if="!isHiddenNewNote && isStatusActive" class="tabs-nav-right-btn">
              <button @click="addNote"
                      data-toggle="modal" data-target="#noteBoxModal" type="button"
                      class="btn btn-primary w-min-sm waves-effect waves-light"
                      style="margin-right:5px"><i class="ti-plus"></i> New Note
              </button>
            </div>
            <div class="tabs-nav-right-options" v-if="!isHiddenOptions">
              <div class="navbar-nav nav dropdown dropdown-options  show inline">
                <button type="button"
                        class="btn waves-effect waves-light ex-options-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                        @click="updateHeightOptionsMenu($event)">
                  Options <span class="dropdown-arrow  pull-right" style="
                                                  box-sizing: border-box;
                                                  width: 32px;
                                                  border-left: 1px solid rgba(27,30,56,0.25);
                                                  ">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated fadeInUp" aria-labelledby="dropdownMenuLink"
                     :style="{'max-height': heightOptions + 'px'}"
                     :class="{'options-menu-height': heightOptions != ''}">
                  <a @click="showPreviewExcessInvoiceModal" class="dropdown-item"> Print Excess Invoice</a>
                  <a @click="downloadExcessReceipt" class="dropdown-item"> Print Receipt</a>
                  <a v-if="isStatusActive" @click="showCustomerModal" class="dropdown-item"> Send To Customer</a>
                  <a v-if="vendorInfo.activeXeroAuth && !invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero"
                     @click="sendToXero" class="dropdown-item">Send to Xero</a>
                  <a v-if="vendorInfo.activeXeroAuth &&  invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero"
                     @click="sendToXero" class="dropdown-item">Update on Xero</a>
                  <a v-if="vendorInfo.activeXeroAuth &&  invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero"
                     @click="viewOnXero" class="dropdown-item">View on Xero</a>
                  <!--                                <a @click="markAsAwaitingPayment" class="dropdown-item"> Mark As Awaiting Payment</a>-->
                  <a v-if="isStatusActive" @click="markAsVoidInvoice" class="dropdown-item"> Mark As Void</a>
                  <a v-if="(isStatusAwaitingPayment || invoice.due!=0) && isStatusActive" @click="showPaidModal"
                     class="dropdown-item"> Mark As Paid</a>
                  <a v-if="(isStatusPaid || +invoice.amount !== invoice.due) && isStatusActive" @click="markAsUnpaid"
                     class="dropdown-item"> Mark As Unpaid</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="classTabContent">
          <div v-if="currentTab === 'details'" id="invoice-details">
            <div class="top_section_invoice_vehicle_row">
              <div class="col_invoice">
                <div class="card ex-card ex-invice-information">
                  <div class="card-header bg-navyblue">
                    <strong>&nbsp;Invoice To</strong>
                  </div>
                  <div class="card-block bg-white">
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Invoice Number</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" v-model="invoice.number" readonly>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Customer</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" v-model="invoice.customer.name"
                               @blur="blurCustomerName" @click="editCustomerName" @change="changeCustomerName"
                               :readonly="isReadOnlyCustomerName || !isStatusActive">

                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Customer Address</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" v-model="invoice.customer.address"
                               @blur="blurCustomerAddress" @click="editCustomerAddress" @change="changeCustomerAddress"
                               :readonly="isReadOnlyCustomerAddress || !isStatusActive">

                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Invoice Date</label>
                      <div class="col-lg-9">
                        <date-picker v-model="invoice.created_date" :is-disabled="!isStatusActive"
                                     @onChange="autoUpdate"></date-picker>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Due Date</label>
                      <div class="col-lg-9">
                        <date-picker v-model="invoice.due_date" :is-disabled="!isStatusActive"
                                     @onChange="autoUpdate"></date-picker>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Estimate Reference</label>
                      <div class="col-lg-9">
                                                <span class="form-control">
                                                    <router-link v-for="item in card.estimates"
                                                                 :key="'routerLink'+item.id"
                                                                 :to="'/estimates/view/' + item.id"
                                                                 style="padding-right:10px;">{{ item.number }}
                                                    </router-link>
                                                </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Sent</label>
                      <div class="col-sm-7 col-7 d-flex">
                        <label class="form-check-inline radio" style="width:70px;">
                          <input class="form-check-input" :disabled="!isStatusActive" v-model="invoice.is_sent"
                                 type="radio" name="invoice_is_sent"
                                 :value="true" @change="autoUpdate">
                          <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                        </label>
                        <label class="form-check-inline radio">
                          <input class="form-check-input" :disabled="!isStatusActive" v-model="invoice.is_sent"
                                 type="radio" name="invoice_is_sent"
                                 :value="false" @change="autoUpdate">
                          <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col_vehicle">
                <div class="card ex-card ex-card-details">
                  <div class="card-header bg-navyblue">
                    <strong>&nbsp;Vehicle Owner</strong>
                  </div>
                  <div class="own_vehicle_section">
                    <div class="card_owner_details">
                      <div class="card card-in-card">
                        <div class="card-header">Owner Details</div>
                        <div class="card-block bg-white">
                          <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Name</label>
                            <div class="col-lg-8">
                              <input type="text" v-model="card.customer.name" class="form-control" readonly>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Owner Type</label>
                            <div class="col-lg-8">
                              <input type="text" v-model="card.customer.customer_type == 'I'?'Individual':'Business' "
                                     class="form-control" readonly>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Contact Number</label>
                            <div class="col-lg-8">
                              <input type="text" v-model="card.customer.mobile" class="form-control" readonly>
                              <i v-if="card.customer.mobile" @click="onClickNumber(card.customer.mobile)" class='bx bxs-phone-call'></i>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Email Address</label>
                            <div class="col-lg-8">
                              <input type="text" v-model="card.customer.email" class="form-control" readonly>
                            </div>
                          </div>
                          <div class="form-group row" v-if="!!card.insurance_repair">
                            <label class="col-lg-4 col-form-label">Claim Number</label>
                            <div class="col-lg-8">
                              <input type="text" v-model="card.insurance.claim_number" class="form-control" readonly>
                            </div>
                          </div>
                          <div class="form-group row" v-if="!!card.insurance_repair">
                            <label class="col-lg-4 col-form-label">Insurer Name</label>
                            <div class="col-lg-8">
                              <input type="text" v-model="card.insurance.insurer_name" class="form-control" readonly>
                            </div>
                          </div>
                          <div class="form-group row" v-if="!!card.insurance_repair">
                            <label class="col-lg-4 col-form-label">Insurer Address</label>
                            <div class="col-lg-8">
                              <input type="text" v-model="card.insurance.insurer_location" class="form-control"
                                     readonly>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card_vehicle_details">
                      <div class="card card-in-card">
                        <div class="card-header">Vehicle Details</div>
                        <div class="card-block bg-white">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Make</label>
                                <div class="col-lg-8">
                                  <input :readonly="!isStatusActive" type="text" v-model="card.vehicle.make"
                                         class="form-control">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Model</label>
                                <div class="col-lg-8">
                                  <input :readonly="!isStatusActive" type="text" v-model="card.vehicle.model"
                                         class="form-control">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Series</label>
                                <div class="col-lg-8">
                                  <input :readonly="!isStatusActive" type="text" v-model="card.vehicle.series"
                                         class="form-control">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Transmission</label>
                                <div class="col-lg-8">
                                  <multiselect
                                      v-model="card.vehicle.transmission"
                                      :options="['Automatic','Manual']"
                                      :showLabels="false"
                                      :option-height="29"
                                      :disabled="!isStatusActive"
                                      style="opacity: 1"
                                      :max-height="203"
                                      :close-on-select="true"
                                  ></multiselect>

                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Paint Code</label>
                                <div class="col-lg-8">
                                  <input :readonly="!isStatusActive" type="text" v-model="card.vehicle.colour"
                                         class="form-control">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Date Booked (Start)</label>
                                <div class="col-lg-8">
                                  <date-picker :is-disabled="!isStatusActive" v-model="card.booking_date"
                                               @onChange="autoUpdate"></date-picker>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Date Completed</label>
                                <div class="col-lg-8">
                                  <date-picker :is-disabled="!isStatusActive" v-model="card.completion_date"
                                               @onChange="autoUpdate"></date-picker>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">

                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Rego</label>
                                <div class="col-lg-8">
                                  <input :readonly="!isStatusActive" type="text" v-model="card.vehicle.rego_number"
                                         class="form-control">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">VIN Number</label>
                                <div class="col-lg-8">
                                  <input :readonly="!isStatusActive" type="text" v-model="card.vehicle.vin"
                                         class="form-control">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Cylinders</label>
                                <div class="col-lg-8">
                                  <input :readonly="!isStatusActive" type="text" v-model="card.vehicle.cylinders"
                                         class="form-control">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Build Date</label>
                                <div class="col-lg-8">
                                  <date-picker :is-disabled="!isStatusActive" @onChange="autoUpdate"
                                               v-model="card.vehicle.build_date"></date-picker>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Odometer</label>
                                <div class="col-lg-8">
                                  <number-formatter :is-disabled="!isStatusActive" v-model="card.vehicle.odometer"
                                                    type="text" class="form-control" placeholder="Odometer"
                                                    format=""></number-formatter>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-lg-8 padding-additional-class">
                <div class="card invoice-view ex-card ex-quote-information">
                  <div class="card-header bg-navyblue">
                    <strong> Charges</strong>
                  </div>
                  <div class="bg-white">
                    <div class="top_header_invoice">
                      <div class="left_complate_invoice">
                        <b>Description</b>
                      </div>
                      <div class="expand_amount">
                        <strong>Amount</strong>
                      </div>
                    </div>
                    <table class="table main-table tab-special mb-0">
                      <tbody>
                      <tr class="qtit">
                        <td>Excess</td>
                        <td></td>
                        <td></td>
                        <td>{{ summary.Excess_str }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card invoice-view ex-card ex-quote-information no-border">
                  <div class="card-block bg-white result_of_total_amount">
                    <table class="parts-total main-table main-total-table latest_amount_table">
                      <tbody>
                      <tr class="latest_amount" style="font-weight: bold; font-size: 13px;">
                        <td class="color-dark-grey">Total</td>
                        <td class="color-dark-grey">{{ summary.Excess_str }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
              <div class="col-md-12 col-lg-4">
                <div class="card card-section">
                  <div class="card-header">
                    <strong>Card</strong>
                  </div>
                  <div class="card-block bg-white">
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label">Card Number</label>
                      <div class="col-lg-8">
                        <span class="form-control"><router-link
                            :to="'/cards/view/' + card.card_id">{{ card.card_number }}</router-link></span>
                      </div>
                    </div>
                  </div>
                  <div class="card ex-card wizard-bottom">
                    <small-form-wizard
                        title="Progress"
                        :current-status-id="card.card_status"
                        :statuses="cardProgressBarStatuses"
                        :progress="cardProgress.widthActivePercent"
                        :isShowFullActivity="false"
                        @change="onChangeCardProgress"
                    />
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-8 padding-additional-class">
                <div class="card ex-card ex-card-details">
                  <div class="card-header bg-navyblue">
                    <strong>&nbsp;Payment Terms</strong>
                  </div>
                  <div>
                    <div v-html="terms" disabled class="invoice-terms"></div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-12 col-lg-8"></div>
            </div>
          </div>

          <!-- transaction hiotory / activity -->
          <div v-if="currentTab === 'transaction'" id="activity">
            <div class="row no-gutter">
              <div class="col-md-6">
                <div class="row activity-list-header px-0 no-gutter">
                  <div class="col-md-6 col-xs-6">
                    <h4 class="tab-pane__title">Transaction History</h4>
                  </div>
                  <div class="col-md-6 col-xs-6 text-right">
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutter">
              <div class="col-md-6 col-xs-12">
                <div class="timeline">
                  <div v-for="(a, index) in invoice.transactions" :key="'tr'+index" class="tl-item tl-activity-item">
                    <div class="tl-wrap b-a-primary"
                         v-if="a.transaction_type == 'Paid' || a.transaction_type == 'EIC' || a.transaction_type == 'EIP' || a.transaction_type == 'Correction' || a.transaction_type == 'Info'">
                      <i class="fa fa-usd left" aria-hidden="true"></i>
                      <div class="tl-content box box-block display-block">
                        <span class="arrow left" style="border-color: #FAFAFA;"></span>
                        <table v-if="a.transaction_type == 'Paid' || a.transaction_type == 'Correction'">
                          <tr>
                            <td style="padding-right:25px; width:160px;">Payment Type:</td>
                            <td>{{ a.payment_type }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Amount Due:</td>
                            <td>{{ a.amount_due_str }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Amount Paid:</td>
                            <td>{{ a.amount_str }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Date Paid:</td>
                            <td>{{ a.date }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Amount Outstanding:</td>
                            <td>{{ a.amount_outstanding_str }}</td>
                          </tr>
                        </table>
                        <table v-if="a.transaction_type == 'Info'">
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Transaction:</td>
                            <td>{{a.payment_message}}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Date:</td>
                            <td>{{ a.date }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Processed by:</td>
                            <td>{{ a.processedBy }}</td>
                          </tr>
                        </table>
                        <table v-if="a.transaction_type == 'EIC'">
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Transaction:</td>
                            <td>Excess Invoice Created</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Amount Due:</td>
                            <td>{{ a.amount_due_str }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Date:</td>
                            <td>{{ a.date }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Processed by:</td>
                            <td>{{ a.processedBy }}</td>
                          </tr>
                        </table>
                        <table v-if="a.transaction_type == 'EIP'">
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Transaction:</td>
                            <td>Excess Invoice Payment Received</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Payment Type:</td>
                            <td>{{ a.payment_type }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Amount Due:</td>
                            <td>{{ a.amount_due_str }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Amount Paid:</td>
                            <td>{{ a.amount_str }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Date:</td>
                            <td>{{ a.date }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Amount Outstanding:</td>
                            <td>{{ a.amount_outstanding_str }}</td>
                          </tr>
                          <tr>
                            <td style="padding-right:25px;  width:160px;">Processed by:</td>
                            <td>{{ a.processedBy }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">

              </div>
            </div>

          </div>
          <div v-if="currentTab === 'notes'" id="card-notes">
            <invoice-notes
                ref="cardNotes"
                :notes="notes"
                :invoice_id="invoice.id"
                @loadNotes="loadNotes">
            </invoice-notes>
          </div>
        </div>
      </div>
    </div>

    <modal-wizard ref="previewExcessInvoiceModal" cancelButtonText="Close" finishButtonText="Print PDF"
                  @on-complete="downloadExcessInvoice">
      <modal-wizard-tab title="Preview Excess Invoice" description="" title-icon-class="ti-align-right"
                        :tabData="previewInvoice">
        <template slot-scope="props">
          <div style="display: none">{{ sctollToTop() }}</div>

          <div class="invoice">
            <header class="invoice-header">
              <div class="invoice-logo">
                <img :src="vendorInfo.logo" style="height: 150px; width: 300px;" alt="">
              </div>
              <div class="invoice-info">
                <h1 class="invoice-title">TAX INVOICE</h1>
                <table class="header-table">
                  <tr>
                    <td class="font-14 font-bold">{{ props.vendor.business_name }}</td>
                  </tr>
                  <tr>
                    <td><span class="font-bold">ABN</span> : {{ props.vendor.abn }}</td>
                  </tr>
                  <tr>
                    <td>License : {{ props.vendor.license_number }}</td>
                  </tr>
                  <tr v-if="props.vendor.postal_address">
                    <td>{{ props.vendor.postal_address }}</td>
                  </tr>
                  <tr v-if="!props.vendor.postal_address">
                    <td>{{ props.vendor.postal_street }} {{ props.vendor.postal_suburb }}</td>
                  </tr>
                  <tr v-if="!props.vendor.postal_address">
                    <td>{{ props.vendor.postal_state }} {{ props.vendor.postal_country }}</td>
                  </tr>
                  <tr>
                    <td>Email : {{ props.vendor.email }}</td>
                  </tr>
                  <tr>
                    <td>Phone : {{ props.vendor.phone_area_code }} {{ props.vendor.phone }}</td>
                  </tr>
                </table>
                <table class="header-table">
                  <tr>
                    <td><strong>Invoice To :</strong></td>
                  </tr>
                  <tr>
                    <td>{{ props.invoice.customer.name }}</td>
                  </tr>
                  <tr v-if="props.invoice.customer.address">
                    <td>{{ props.invoice.customer.address }}</td>
                  </tr>
                  <tr v-if="props.invoice.customer.address === null">
                    <td>{{ props.card.customer.street_name }} {{ props.card.customer.suburb }}</td>
                  </tr>
                  <tr v-if="props.invoice.customer.address === null">
                    <td>{{ props.card.customer.state }} {{ props.card.customer.country }}</td>
                  </tr>
                </table>

                <table class="header-table">
                  <tr>
                    <td><strong>Invoice Number :</strong> <span>{{ props.invoice.number }}</span></td>
                  </tr>
                  <tr>
                    <td><strong>Invoice Date :</strong> <span>{{ props.invoice.created_date }}</span></td>
                  </tr>
                  <tr>
                    <td><strong>Due Date :</strong> <span>{{ props.invoice.due_date }}</span></td>
                  </tr>
                </table>
              </div>
            </header>

            <main>
              <table class="main-table m0 vehicle-owner-block">
                <thead>
                <tr>
                  <th colspan="2">Vehicle &amp; Owner</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th>Owner Details</th>
                  <th>Vehicle Details</th>
                </tr>
                <tr>
                  <td class="w50 vat">
                    <table class="main-inner owner-details">
                      <tr>
                        <td>Owner Name</td>
                        <td>{{ props.card.customer.name }}</td>
                      </tr>
                      <tr>
                        <td>Home Phone</td>
                        <td>{{ props.card.customer.phone }}</td>
                      </tr>
                      <tr>
                        <td>Mobile Phone</td>
                        <td>{{ props.card.customer.mobile }}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td style="min-width: 215px; word-break: break-all">{{ props.card.customer.email }}</td>
                      </tr>
                      <tr v-if="props.card.customer.customer_type == 'B'">
                        <td>ABN</td>
                        <td>{{ props.card.customer.abn }}</td>
                      </tr>
                      <tr>
                        <td>Insurer Name</td>
                        <td>{{ props.card.insurance.insurer_name }}</td>
                      </tr>
                      <tr>
                        <td>Insurer ABN</td>
                        <td>{{ props.card.insurance.abn }}</td>
                      </tr>
                      <tr>
                        <td>Claim Number</td>
                        <td>{{ props.card.insurance.claim_number }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="w50 vat">
                    <table class="vehicle-details">
                      <tr>
                        <td class="w50 vat">
                          <table class="main-inner">
                            <tr>
                              <td>Make</td>
                              <td>{{ props.card.vehicle.make }}</td>
                            </tr>
                            <tr>
                              <td>Model</td>
                              <td>{{ props.card.vehicle.model }}</td>
                            </tr>
                            <tr>
                              <td>Series</td>
                              <td v-if="!props.properties.isHideSeries">{{ props.card.vehicle.series }}</td>
                            </tr>
                            <tr>
                              <td>Transmission</td>
                              <td>{{ props.card.vehicle.transmission }}</td>
                            </tr>
                            <tr>
                              <td>Paint Code</td>
                              <td>{{ props.card.vehicle.colour }}</td>
                            </tr>
                            <tr>
                              <td>Date Booked (Start)</td>
                              <td>{{ props.card.booking_date }}</td>
                            </tr>
                          </table>
                        </td>
                        <td class="w50 vat">
                          <table class="main-inner" align="right">
                            <tr>
                              <td>Date Completed</td>
                              <td>{{ props.card.completion_date }}</td>
                            </tr>
                            <tr>
                              <td>Rego</td>
                              <td>{{ props.card.vehicle.rego_number }}</td>
                            </tr>
                            <tr>
                              <td>VIN Number</td>
                              <td>{{ props.card.vehicle.vin }}</td>
                            </tr>
                            <tr>
                              <td>Cylinders</td>
                              <td>{{ props.card.vehicle.cylinders }}</td>
                            </tr>
                            <tr>
                              <td>Build Date</td>
                              <td>{{ props.card.vehicle.build_date }}</td>
                            </tr>
                            <tr>
                              <td>Odometer</td>
                              <td>{{ props.card.vehicle.odometer }}</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                </tbody>
              </table>
              <div>
                <table class="main-table m0">
                  <thead>
                  <tr>
                    <th>Charges</th>
                  </tr>
                  </thead>
                </table>
              </div>
              <div>
                <table class="main-table estimate-repairs m0">
                  <thead class="bg-white th-fz12">
                  <tr>
                    <th>Description</th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>

                    <th class="text-center">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Excess</td>
                    <td></td>
                    <td></td>
                    <td class="text-center" style="padding-left:15px">{{ props.summary.Excess_str }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <table class="main-table  m0 divider-total">
                <thead>
                </thead>
                <tbody>
                <tr>
                  <td></td>
                </tr>
                </tbody>
              </table>
              <table class="parts-total main-table main-total-table">
                <tbody>

                <tr>
                  <td class="color-dark-grey"><strong>Total</strong></td>
                  <td class="color-dark-grey"><strong>{{ props.summary.Excess_str }}</strong></td>
                </tr>
                </tbody>
              </table>

            </main>
            <footer>
              <table class="footer-table">
                <thead>
                <tr>
                  <th>Terms</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td v-html="props.terms"></td>
                </tr>
                </tbody>
              </table>
              <table class="footer-table">
                <thead>
                <tr>
                  <th>Payment Options</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </footer>
          </div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>

    <modal-wizard id="voidInvoiceModal" ref="voidInvoiceModal" cancelButtonText="Close"
                  finishButtonText="Void Excess Invoice" @on-complete="completeVoidInvoice">
      <modal-wizard-tab title="Void Excess Invoice" description="" title-icon-class="ti-align-right" :tabData="card">
        <template slot-scope="props">
          <div>Are you sure you want to delete this Excess Invoice?</div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>
  </div>
</template>

<style scoped>
.vehicle-owner-block tbody >>> tr td{
  vertical-align: top;
}
.vehicle-owner-block .w50{
  padding: 5px;
}
.bxs-phone-call{
  position: absolute;
  top: 5px;
  right: 25px;
  font-size: 22px;
  color: #1d4898;
  cursor: pointer;
}

.V3 label.radio > input[type="radio"]:checked:disabled + span.icon i {
  color: white;
}

.V3 label.radio > input[type="radio"]:checked + span.icon {
  background-color: #5E79FF;
}

.V3 .invoice-terms {
  border-radius: 3px;
  background-color: rgba(27, 30, 56, 0.03);
  margin: 5px 7px;
  padding: 9px 13px;
}

.tab-content {
  padding-top: 10px;
  position: relative;
}

.tab-content::after {
  content: '';
  top: 0px;
  width: 100%;
  height: 10px;
  background: #e3e4e7;
}

.no-border {
  border: none;
}

.latest_amount_table tbody > tr {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.latest_amount_table .latest_amount > td:last-child {
  font-size: 23px;
  font-weight: 600;
  color: #000000;
}

.latest_amount_table .latest_amount > td:first-child {
  color: #000000;
}

.latest_amount_table .latest_amount {
  border-top-width: 1px;
  border-top-style: solid;
  position: relative;
  border-top-color: buttonface;
  margin-top: 19px;
  padding-top: 10px;
}
</style>

<style>
@media(max-width: 497px) {
  #voidInvoiceModal .modal-footer {
    padding-left: 10px !important;
    justify-content: center;
    display: flex;
    padding-right: 10px;
  }
}

@media(max-width: 434px) {
  #voidInvoiceModal .modal-footer {
    flex-direction: column;
    padding-right: 15px;
    padding-left: 15px !important;
  }

  #voidInvoiceModal .modal-footer #finishModalWizardButton {
    margin-bottom: 10px;
    margin-right: 0px !important;
  }
}


.vue-form-wizard.xs {
  padding-bottom: 0px !important;
  overflow: auto;
}

.wizard-nav {
  overflow: hidden;
}

.vue-form-wizard .wizard-nav-pills a, .vue-form-wizard .wizard-nav-pills li {
  max-width: 89px;
}

.vue-form-wizard.xs .wizard-icon-circle {
  width: 30px !important;
  height: 30px !important;
}

.wizard-icon.ti-check {
  font-weight: bold;
}

.vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
  height: 2px !important;
  top: 19px !important;
}

.vue-form-wizard.xs .wizard-nav-pills > li.active > a .wizard-icon {
  font-size: 12px !important;
}

.wizard-navigation {
  overflow-x: auto;
}

.vue-form-wizard .wizard-icon-circle .wizard-icon-container {
  border-radius: 50%;
}

.act-dd.act-dd-right {
  right: -9px !important;
}

.srt-dd-right {
  right: 25px !important;
  max-width: 130px;
  width: 130px;
}

.activity-list-header {
  padding: 15px;
}

.srt-dd-right.dropdown-menu i {
  position: relative;
  top: 0px;
  display: inline-block;
  padding-right: 5px;
}

.btn-outline-primary-light {
  color: #5883CC !important;
  border-color: #5883CC !important;
}

.btn-outline-primary-light:hover {
  color: #FFFFFF !important;
  background: #5883CC !important;
  border-color: #5883CC !important;
}

.border-top {
  border: 0.5px solid #DCDCDC;
}

.dropzoneContainer {
  margin-bottom: 15px;
}

.btnNotBorder {
  border-color: #FAFAFA !important;
}

.btnNotBorder:hover {
  background-color: #FAFAFA !important;
  color: #142742 !important;
}

.btnNotBorder .fa-sort-down {
  left: unset !important;
  margin: 0 !important;
  top: 5px !important;
  font-size: 14px !important;
  width: 20px !important;
  height: auto !important;
}

.dropdownLinks-container {
  flex-direction: column !important;
  position: absolute !important;
  border: none !important;
  background-color: #FAFAFA !important;
  right: 20px !important;
  top: 28px !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}

.dropdownLinks-links {
  position: relative;
  background: white;
  border: 1px solid rgba(0, 0, 0, .25) !important;
}

.dropdownLinks-links:after,
.dropdownLinks-links:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.dropdownLinks-links:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #FFFFFF;
  border-width: 5px;
  right: 12px;
  background-clip: padding-box;
}

.dropdownLinks-links:before {
  border-color: rgba(184, 184, 184, 0);
  border-bottom-color: #B8B8B8;
  border-width: 6px;
  right: 11px;
  background-clip: padding-box;
}

.activtyPopupIcon {
  position: unset !important;
  margin: 0 !important;
  font-size: 10px !important;
}

.autosave {
  color: #142742;
  font-size: 13px;
  margin-left: 100px;
}

.invoice-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.invoice .main-table {
  margin: 0 0 20px;
  width: 100%;
  border-collapse: collapse
}

.invoice .main-table tr.empty-row td, .invoice .main-table tr.empty-row th {
  height: 20px
}

.invoice .main-table .vehicle-details {
  width: 100%
}

.invoice .parts-required {
  margin-bottom: 0
}

.invoice .parts-required td:nth-child(1), .invoice .parts-required th:nth-child(1) {
  width: 355px
}

.invoice .parts-required td:last-child, .invoice .parts-required th:last-child {
  width: 70px
}

.invoice .estimate-repairs td:first-child, .invoice .estimate-repairs th:first-child {
  width: 550px
}

.invoice .estimate-repairs td:nth-child(2), .invoice .estimate-repairs th:nth-child(2) {
  width: 55px
}

.invoice .estimate-repairs td:last-child, .invoice .estimate-repairs th:last-child {
  width: 70px
}

.invoice .estimate-other td:first-child, .invoice .estimate-other th:first-child {
  width: 550px
}

.invoice .estimate-other td:nth-child(2), .invoice .estimate-other th:nth-child(2) {
  width: 55px
}

.invoice .estimate-other td:last-child, .invoice .estimate-other th:last-child {
  width: 70px
}

.invoice .estimate-sublet td:first-child, .invoice .estimate-sublet th:first-child {
  width: 605px
}

.invoice .estimate-sublet td:last-child, .invoice .estimate-sublet th:last-child {
  width: 70px
}

.invoice .estimate-parts td:first-child, .invoice .estimate-parts th:first-child {
  width: 400px
}

.invoice .estimate-parts td:nth-child(2), .invoice .estimate-parts th:nth-child(2) {
  width: 150px
}

.invoice .estimate-parts td:nth-child(3), .invoice .estimate-parts th:nth-child(3) {
  width: 55px
}

.invoice .estimate-parts td:last-child, .invoice .estimate-parts th:last-child {
  width: 70px
}

.invoice .parts-total td:last-child {
  width: 70px;
  padding-left: 15px
}

.invoice .tax-subtotal-hrs td:first-child {
  width: 560px
}

footer {
  clear: both
}

.footer-table {
  width: 100%;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver
}

.footer-table td, .footer-table th {
  text-align: left
}

.footer-table th {
  padding: 16px 8px 10px;
  font-size: 12px
}

.footer-table td {
  padding: 0 8px 16px;
  font-size: 11px;
  color: #595959
}

.invoice-view .main-table thead {
  background: #F2F2F2
}

.invoice-view .main-table thead th {
  padding: .75rem;
}

.invoice-view .main-table tfoot td {
}

#card-details .supplement-number {
  padding: 1rem 0;
  font-size: 1.3rem;
  font-weight: bold;
}

#card-details .total {
  padding: 1rem 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.card-in-card {
  margin-bottom: 0;
}

.card-in-card .card-header {

}

.V3 .invoice-view-page .modal .invoice {
  width: 820px;
  margin: 25px auto
}

@media screen and (max-width: 991px) {
  .V3 .invoice-view-page .modal .invoice {
    width: 540px;
  }
}

@media screen and (max-width: 767px) {
  .V3 .invoice-view-page .modal .invoice {
    width: 100%;
  }

  .V3 .invoice-view-page .modal .tab-content {
    padding: 10px 0 !important;
  }
}
</style>

<style scoped>
.modal-styled-block {
  background-color: #EFEFF1 !important;
  color: black !important;;
}

.btn-styled {
  height: 37px;
  width: 77px;
  border-radius: 3px;
}

.th-width-30 {
  width: 35%;
}

.th-width-50 {
  width: 56%;
}

.th-width-20 {
  width: 9%;
}

@media screen and (max-width: 768px) {
  .header-display-name {
    display: none;
  }
}

.top_header_invoice {
  padding: 0.75rem 15px;
}

@media screen and (min-width: 991px) {
  .padding-additional-class {
    padding-right: 15px !important;
  }
}

.V3 .card-block {
  padding-left: 0px;
  padding-right: 0px;
}

.V3 .invoice-excess-page .modal .invoice {
  width: 820px;
  margin: 25px auto;
}

</style>
