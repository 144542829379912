<template>
  <div class="reports-page table-view">
    <div class="page-header">
      <h4>View Reports</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item" :class="{'active': isShowReportsList, 'reports-link-active': !isShowReportsList}" @click="goToReports">Reports Menu</li>
        <li v-if="computedSelectedOptionName" class="breadcrumb-item active">{{computedSelectedOptionName}}</li>
      </ol>
      <h4 class="header-display-name">{{ getterBusinessName }}</h4>
    </div>
    <div class="box box-block bg-white cards">
      <div v-if="isShowReportsList" class="row no-gutter">
        <div class="col-12 col-md-4 report-col ">
          <div class="card">
            <div class="">
              <div class="card-header bg-navyblue">
                <strong>Estimates</strong>
              </div>
              <div class="card-block bg-white">
                <ul class="report-list">
                  <li class="report-option first-option" @click="selectOption('EPS')">Estimates Processed Summary</li>
                  <li class="report-option" @click="selectOption('EVS')">Estimates Value Summary</li>
                  <li class="report-option" @click="selectOption('AEV')">Average Estimate Value</li>
                  <li class="report-option" @click="selectOption('EINS')">Estimate values by Insurer and Non-Insurance Summary</li>
                  <li class="report-option last-option" @click="selectOption('EVI')">Estimate Values by Insurer</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="">
              <div class="card-header bg-navyblue">
                <strong>Payables & Receivables</strong>
              </div>
              <div class="card-block bg-white">
                <ul class="report-list">
                  <li class="report-option first-option" @click="selectOption('RID')" style="border-bottom: none">Receivables Invoice Detail</li>
<!--                  <li class="report-option last-option" @click="selectOption('RIS')">Receivables Invoice Summary</li>-->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 report-col">
          <div class="card">
            <div class="">
              <div class="card-header bg-navyblue">
                <strong>Invoices</strong>
              </div>
              <div class="card-block bg-white">
                <ul class="report-list">
                  <li class="report-option first-option" @click="selectOption('IPS')">Invoices Processed Summary</li>
                  <li class="report-option" @click="selectOption('IVS')">Invoices Value Summary</li>
                  <li class="report-option" @click="selectOption('AIV')">Average Invoice Value</li>
                  <li class="report-option" @click="selectOption('IINS')">Invoice values by Insurer and Non-Insurance Summary</li>
                  <li class="report-option last-option" @click="selectOption('IVI')">Invoice Values by Insurer</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutter chart-block">
        <estimates-processed-summary v-if="selectedOption === 'EPS'"></estimates-processed-summary>
        <estimates-value-summary     v-if="selectedOption === 'EVS'"></estimates-value-summary>
        <average-estimate-value      v-if="selectedOption === 'AEV'"></average-estimate-value>
        <estimate-values-by-insurer-and-non-insurance-summary v-if="selectedOption === 'EINS'"></estimate-values-by-insurer-and-non-insurance-summary>
        <estimate-values-by-insurer  v-if="selectedOption === 'EVI'"></estimate-values-by-insurer>

        <invoices-processed-summary v-if="selectedOption === 'IPS'"></invoices-processed-summary>
        <invoices-value-summary     v-if="selectedOption === 'IVS'"></invoices-value-summary>
        <average-invoice-value      v-if="selectedOption === 'AIV'"></average-invoice-value>
        <invoice-values-by-insurer-and-non-insurance-summary v-if="selectedOption === 'IINS'"></invoice-values-by-insurer-and-non-insurance-summary>
        <invoice-values-by-insurer  v-if="selectedOption === 'IVI'"></invoice-values-by-insurer>

        <receivables-invoice-summary v-if="selectedOption === 'RIS'"></receivables-invoice-summary>
        <receivables-invoice-details v-if="selectedOption === 'RID'"></receivables-invoice-details>

      </div>
    </div>

  </div>
</template>

<script>
  import EstimatesProcessedSummary from './charts/estimates/processed-summary';
  import EstimatesValueSummary from './charts/estimates/value-summary';
  import AverageEstimateValue from './charts/estimates/average-value';
  import EstimateValuesByInsurerAndNonInsuranceSummary from './charts/estimates/values-by-insrer-and-non-insurance-summary';
  import EstimateValuesByInsurer from './charts/estimates/value-by-insurer';

  import InvoicesProcessedSummary from './charts/invoices/processed-summary';
  import InvoicesValueSummary from './charts/invoices/value-summary';
  import AverageInvoiceValue from './charts/invoices/average-value';
  import InvoiceValuesByInsurerAndNonInsuranceSummary from './charts/invoices/values-by-insrer-and-non-insurance-summary';
  import InvoiceValuesByInsurer from './charts/invoices/values-by-insurer';

  import ReceivablesInvoiceDetails from './charts/payables/receivablesInvoiceDetails';
  import ReceivablesInvoiceSummary from './charts/payables/receivablesInvoiceSummary';
  import { mapGetters } from 'vuex';

  export default {
    name: "reports",
    components: {
      EstimatesProcessedSummary,
      EstimatesValueSummary,
      AverageEstimateValue,
      EstimateValuesByInsurerAndNonInsuranceSummary,
      EstimateValuesByInsurer,

      InvoicesProcessedSummary,
      InvoicesValueSummary,
      AverageInvoiceValue,
      InvoiceValuesByInsurerAndNonInsuranceSummary,
      InvoiceValuesByInsurer,

      ReceivablesInvoiceSummary,
      ReceivablesInvoiceDetails,
    },
    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        isShowReportsList: 'report/isShowReportsList',
        selectedOption: 'report/selectedOption',
        getterBusinessName : 'getBusinessName',
      }),
      computedSelectedOptionName() {
        if (!this.selectedOption) {
          return ''
        }
        if (this.selectedOption == 'EPS') {
          return 'Estimates Processed Summary'
        } else if (this.selectedOption == 'EVS') {
          return 'Estimates Value Summary'
        } else if (this.selectedOption == 'AEV') {
          return 'Average Estimate Value'
        } else if (this.selectedOption == 'EINS') {
          return 'Estimate values by Insurer and Non-Insurance Summary'
        } else if (this.selectedOption == 'EVI') {
          return 'Estimate Values by Insurer'
        } else if (this.selectedOption == 'RID') {
          return 'Receivables Invoice Detail'
        } else if (this.selectedOption == 'IPS') {
          return 'Invoices Processed Summary'
        } else if (this.selectedOption == 'IVS') {
          return 'Invoices Value Summary'
        } else if (this.selectedOption == 'AIV') {
          return 'Average Invoice Value'
        } else if (this.selectedOption == 'IINS') {
          return 'Invoice values by Insurer and Non-Insurance Summary'
        } else if (this.selectedOption == 'IVI') {
          return 'Invoice Values by Insurer'
        }
      }
    },
    methods: {
      selectOption(option) {
        this.$store.commit('report/setSelectedOption', option);
        this.$store.commit('report/setIsShowReportList', false);
      },
      goToReports() {
        this.$store.commit('report/setIsShowReportList', true);
        this.$store.commit('report/setSelectedOption', '');
      }
    },
  }
</script>

<style scoped>
.reports-page .report-list {
  list-style: none;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
}
.reports-page .report-list .report-option {
  border-bottom: 1px solid rgba(0,0,0,.2);
  padding: 10px;
  font-weight: bold;
}
.reports-page .report-list .report-option:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
.reports-page .report-list .first-option {
  border-radius: 6px 6px 0px 0px;
}
.reports-page .report-list .last-option {
  border-radius: 0px 0px 6px 6px;
  border-bottom: none !important;
}
.reports-page .report-col {
  margin: 15px;
}
.reports-page .card-block {
  padding: 15px 5px;
}
.reports-page .chart-block {
  padding: 0px;
  margin-top: 45px;
  position: absolute;
  left: -80px;
}
.reports-page .reports-link-active {
  cursor: pointer;
}
</style>
<style>
.reports-page .no-data-text {
  width: 892px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
}
.reports-page .chart-interval-block {
  margin-right: 138px;
  color: #1C1F39;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  width: 892px;
}
.reports-page .header-right {
  padding-bottom: 5px;
  float: right;
}
.reports-page .chart-wrapper .report-table{
  margin: 20px 0 40px 95px;
  width: 882px;
}

.reports-page .chart-title {
  font-size: 1rem;
  line-height: 1.5;
  color: black;
  font-weight: 800;
  margin-left: 110px;
}
.reports-page .header-content {
  width: 958px;
}
</style>
